var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { useContext, useState } from "react";
import { Title } from "./title";
import translator from "../../../../../tagwalk-api-client/assets/js/translator";
import { fetchApi, translate } from "../../helpers";
import { AuthContext } from '../contexts';
import { HttpMethods } from "tagwalk-api-client/assets/js/common/enums";
var SuccessBlock = function (_a) {
    var action = _a.action;
    var _b = useState(''), error = _b[0], setError = _b[1];
    var user = useContext(AuthContext).user;
    var resendEmailUrl = 'api_register_validate_email';
    var translation = translate(error, {
        'support_email': 'support@tag-walk.com'
    });
    var resendValidationEmail = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 4]);
                    return [4, fetchApi(resendEmailUrl, HttpMethods.post, {}, {
                            'email': user === null || user === void 0 ? void 0 : user.email
                        })];
                case 1:
                    response = _b.sent();
                    return [4, response.json()];
                case 2:
                    data = _b.sent();
                    if (!response.ok) {
                        setError(data);
                    }
                    return [3, 4];
                case 3:
                    _a = _b.sent();
                    setError('auth.validation_email_resend.error');
                    return [3, 4];
                case 4: return [2];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "logo flex-center" },
            React.createElement("img", { src: "/images/watermark-black.png", alt: translate('auth.alt.logo') })),
        React.createElement(Title, null, (user === null || user === void 0 ? void 0 : user.newsletter) && (user === null || user === void 0 ? void 0 : user.registrationOrigin) !== 'facebook' && (user === null || user === void 0 ? void 0 : user.registrationOrigin) !== 'apple'
            ? translator('auth.form.type.create_account.status.success.title_is_newsletter_true')
            : translator('auth.form.type.create_account.status.success.title_is_newsletter_false')),
        (user === null || user === void 0 ? void 0 : user.newsletter) && (user === null || user === void 0 ? void 0 : user.registrationOrigin) !== 'facebook' && (user === null || user === void 0 ? void 0 : user.registrationOrigin) !== 'apple' ? (React.createElement("div", { className: "description" },
            React.createElement("p", null, translator('auth.form.type.create_account.status.success.description_is_newsletter_true_pre_action')),
            React.createElement("button", { className: "unset-styles", onClick: function () { return resendValidationEmail(); } },
                React.createElement("u", null,
                    React.createElement("b", null, translator('auth.form.type.create_account.status.success.description_is_newsletter_true_action')))),
            React.createElement("p", null, translator('auth.form.type.create_account.status.success.description_is_newsletter_true_post_action')))) : (React.createElement("p", null, translator('auth.form.type.create_account.status.success.description_is_newsletter_false'))),
        error &&
            React.createElement("div", { className: "alert-error", dangerouslySetInnerHTML: { __html: translation } }),
        action));
};
export { SuccessBlock };
