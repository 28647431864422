var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { fetchApi, generateDownloadLink, generateRoute, translate } from '../../helpers';
import { HttpMethods } from "tagwalk-api-client/assets/js/common/enums";
import { EyeIcon } from "./eyeIcon";
import { Title } from "./title";
import { ErrorHolder } from "./errorHolder";
import { fetchCountries, fetchGenders, fetchJobs } from "../../../security/api";
import Select from 'react-select';
import { ForgotPassword } from "./forgotPassword";
import { User } from "../types";
import { AuthContext } from '../contexts';
import { ActionTypes, FormBlockMode, FormStatusTypes, FormStepTypes } from '../enums';
var Form = function (_a) {
    var errors = _a.errors, setErrors = _a.setErrors, mode = _a.mode;
    var _b = useState({
        slug: '',
        email: '',
        password: '',
        birthdate: '',
        gender: '',
        jobTitle: '',
        fashionIndustry: false,
        country: '',
        survey: false,
        newsletter: false,
        newsletterSource: '',
        registrationOrigin: '',
        isEmailValidated: false,
        isAccountComplete: false,
        jobNeedsUpdate: false,
        timeDifferenceCreatedAtNow: 0
    }), params = _b[0], setParams = _b[1];
    var countryFieldRef = useRef(null);
    var genderFieldRef = useRef(null);
    var jobTitleFieldRef = useRef(null);
    var _c = useState(false), isPasswordVisible = _c[0], setIsPasswordVisible = _c[1];
    var _d = useState([]), genderOptions = _d[0], setGenderOptions = _d[1];
    var _e = useState([]), countryOptions = _e[0], setCountryOptions = _e[1];
    var _f = useState([]), jobOptions = _f[0], setJobOptions = _f[1];
    var _g = useState(''), hcaptchaResponse = _g[0], setHcaptchaResponse = _g[1];
    var _h = useState(FormStatusTypes.Idle), status = _h[0], setStatus = _h[1];
    var _j = useContext(AuthContext), registrationStep = _j.registrationStep, user = _j.user, setUser = _j.setUser, isConnect = _j.isConnect, setIsAuthModalOpen = _j.setIsAuthModalOpen, registrationSource = _j.registrationSource;
    var _k = useState(false), focused = _k[0], setFocused = _k[1];
    var destroyCaptcha = function () {
        var captchaContainer = document.querySelector('#hCaptcha');
        if (captchaContainer && captchaContainer.firstChild) {
            captchaContainer.removeChild(captchaContainer.firstChild);
        }
    };
    useEffect(function () {
        var hcaptchaScript = document.querySelector('#hcaptchaScript');
        var hcaptchaWindow = window;
        var siteKey = hcaptchaScript === null || hcaptchaScript === void 0 ? void 0 : hcaptchaScript.getAttribute('data-sitekey');
        if (!hcaptchaScript) {
            return;
        }
        function handleLoad() {
            if (!hcaptchaWindow.hcaptcha) {
                setErrors({ 'captcha': 'auth.error.invalid_captcha' });
                return;
            }
            hcaptchaWindow.hcaptcha.render('hCaptcha', {
                sitekey: siteKey,
                callback: function (response) { return setHcaptchaResponse(response); },
            });
        }
        if (hcaptchaScript && hcaptchaWindow.hcaptcha) {
            handleLoad();
        }
        return function () {
            destroyCaptcha();
        };
    }, [isConnect]);
    useEffect(function () {
        setParams(__assign(__assign({}, params), { newsletterSource: params.newsletter ? 'auth_modal' : '' }));
    }, [params.newsletter]);
    useEffect(function () {
        if (countryFieldRef.current) {
            var runEffectCountries = function () { return __awaiter(void 0, void 0, void 0, function () {
                var countries, countriesOptions, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4, fetchCountries()];
                        case 1:
                            countries = _a.sent();
                            countriesOptions = transformForSelect(countries, 'countries');
                            setCountryOptions(countriesOptions);
                            return [3, 3];
                        case 2:
                            error_1 = _a.sent();
                            setErrors({ 'country': 'auth.error.fetching.country' });
                            return [3, 3];
                        case 3: return [2];
                    }
                });
            }); };
            runEffectCountries();
        }
        if (jobTitleFieldRef.current) {
            var runEffectJobs = function () { return __awaiter(void 0, void 0, void 0, function () {
                var jobs, jobsOptions, error_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4, fetchJobs()];
                        case 1:
                            jobs = _a.sent();
                            jobsOptions = transformForSelect(jobs, 'jobs');
                            setJobOptions(jobsOptions);
                            return [3, 3];
                        case 2:
                            error_2 = _a.sent();
                            setErrors({ 'job_title': 'auth.error.fetching.job_title' });
                            return [3, 3];
                        case 3: return [2];
                    }
                });
            }); };
            runEffectJobs();
        }
        if (genderFieldRef.current) {
            var runEffectGenders = function () { return __awaiter(void 0, void 0, void 0, function () {
                var genders, gendersOptions, error_3;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4, fetchGenders()];
                        case 1:
                            genders = _a.sent();
                            gendersOptions = genders.map(function (gender) { return ({
                                label: translate('auth.form.type.create_account.select.' + gender),
                                value: gender
                            }); });
                            setGenderOptions(gendersOptions);
                            return [3, 3];
                        case 2:
                            error_3 = _a.sent();
                            setErrors({ 'gender': 'auth.error.fetching.gender' });
                            return [3, 3];
                        case 3: return [2];
                    }
                });
            }); };
            runEffectGenders();
        }
    }, [registrationStep]);
    var formatDateForInput = function (dateString) {
        if (dateString) {
            var date = new Date(dateString);
            return date.toISOString().split('T')[0];
        }
        else {
            return '';
        }
    };
    var transformForSelect = function (object, translationDomain) {
        return Object.keys(object).map(function (name) { return ({
            label: translate(object[name], {}, translationDomain),
            value: object[name],
        }); });
    };
    var isUserUpdateNotCompleteAccount = function () {
        return registrationStep === FormStepTypes.BirthdateGender
            && params.country !== null
            && params.jobTitle !== null;
    };
    var buttonTextMapping = {
        connect: 'auth.form.type.connect.action',
        create_account_1: !!user ? 'auth.form.type.create_account.action.proceed_inscription' : 'auth.form.type.create_account.action.start',
        create_account_2: isUserUpdateNotCompleteAccount() ? 'auth.form.type.create_account.action.finish' : 'auth.form.type.create_account.action.last_step',
        create_account_3: 'auth.form.type.create_account.action.finish',
        create_account_4: 'auth.form.type.create_account.action.confirm',
        create_account_5: 'auth.form.type.create_account.action.close'
    };
    var buttonTextKey = isConnect ? 'connect' : "create_account_" + registrationStep;
    var getCsrfToken = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4, fetchApi('api_security_generate_csrf_token', HttpMethods.post, {}, { 'csrf_token_id': 'authenticate' })];
                case 1:
                    response = _a.sent();
                    return [4, response.json()];
                case 2:
                    data = _a.sent();
                    if (!response.ok) {
                        setErrors({ 'global': data['message'] });
                        return [2, null];
                    }
                    return [2, data];
                case 3:
                    error_4 = _a.sent();
                    setErrors({ 'global': 'auth.error.default_csrf' });
                    return [2, null];
                case 4: return [2];
            }
        });
    }); };
    var handleConnection = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var url, csrf_token, formDataObject, formData, key, response, main, route, contentType, errorCode, errMessage, error_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    event.preventDefault();
                    url = generateRoute('auth_index');
                    return [4, getCsrfToken()];
                case 1:
                    csrf_token = _a.sent();
                    if (csrf_token === null) {
                        return [2];
                    }
                    formDataObject = {
                        _csrf_token: csrf_token,
                        _password: params.password,
                        _remember_me: 'on',
                        _username: params.email,
                        'h-captcha-response': hcaptchaResponse
                    };
                    formData = new FormData();
                    for (key in formDataObject) {
                        formData.append(key, formDataObject[key]);
                    }
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 5, , 6]);
                    setStatus(FormStatusTypes.Loading);
                    return [4, fetch(url, {
                            method: 'POST',
                            body: formData
                        })];
                case 3:
                    response = _a.sent();
                    if (response.redirected) {
                        main = document.querySelector('main');
                        route = main === null || main === void 0 ? void 0 : main.getAttribute('data-route');
                        window.location.href = generateRoute(route);
                        return [2];
                    }
                    contentType = response.headers.get('content-type');
                    errorCode = response.status;
                    if (!contentType || contentType.indexOf('application/json') === -1 || errorCode !== 403) {
                        setErrors({ 'password': 'auth.error.default_login' });
                        return [2];
                    }
                    return [4, response.json()];
                case 4:
                    errMessage = _a.sent();
                    setErrors({ 'password': errMessage['message'] || 'auth.error.default_login' });
                    setStatus(FormStatusTypes.Success);
                    return [3, 6];
                case 5:
                    error_5 = _a.sent();
                    setStatus(FormStatusTypes.Error);
                    setErrors({ 'password': 'auth.error.default_login' });
                    return [3, 6];
                case 6: return [2];
            }
        });
    }); };
    var displayErrors = function () {
        var form = document.querySelector('.right-block form');
        var errorCount = 0;
        if (form) {
            var selects = form.querySelectorAll('.css-b62m3t-container');
            var inputs = form.querySelectorAll('input');
            if (selects) {
                selects.forEach(function (select) {
                    var key = select.getAttribute('id');
                    if (key !== null && (params[key] === null || params[key] === '')) {
                        setErrors(function (errors) {
                            var _a;
                            return (__assign(__assign({}, errors), (_a = {}, _a[key] = 'auth.error.empty_field', _a)));
                        });
                        var input = select.querySelector('.css-1s2u09g-control');
                        if (input) {
                            input.style.borderColor = '#f8d7da';
                            input.style.borderWidth = 'medium';
                        }
                        errorCount += 1;
                    }
                });
            }
            if (inputs) {
                inputs.forEach(function (input) {
                    var key = input.getAttribute('id');
                    if (key !== null && params[key] === null) {
                        setErrors(function (errors) {
                            var _a;
                            return (__assign(__assign({}, errors), (_a = {}, _a[key] = 'auth.error.empty_field', _a)));
                        });
                        input.style.borderColor = '#f8d7da';
                        input.style.borderWidth = 'medium';
                        errorCount += 1;
                    }
                });
            }
        }
        return errorCount;
    };
    useEffect(function () {
        if (registrationStep === FormStepTypes.EmailPassword && !user && registrationSource) {
            setParams(function (prevParams) { return (__assign(__assign({}, prevParams), { registrationOrigin: registrationSource })); });
        }
    }, [registrationStep, user, registrationSource]);
    var handleInscription = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var errorCount, url, method, csrf_token, transformedParams, apiOptions, response, data, user_1, contentType, errorCode, error_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    event.preventDefault();
                    setErrors([]);
                    errorCount = displayErrors();
                    if (errorCount > 0) {
                        return [2];
                    }
                    url = registrationStep === FormStepTypes.EmailPassword && !user ? ActionTypes.Register : ActionTypes.Patch;
                    method = registrationStep === FormStepTypes.EmailPassword && !user ? HttpMethods.post : HttpMethods.patch;
                    return [4, getCsrfToken()];
                case 1:
                    csrf_token = _a.sent();
                    if (csrf_token === null) {
                        return [2];
                    }
                    transformedParams = __assign(__assign({}, params), { "_token": csrf_token !== null && csrf_token !== void 0 ? csrf_token : '', "h-captcha-response": hcaptchaResponse });
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 5, , 6]);
                    setStatus(FormStatusTypes.Loading);
                    apiOptions = {
                        headers: {},
                        body: transformedParams
                    };
                    if (url !== 'api_register_index') {
                        apiOptions.headers = { 'slug': params['slug'] };
                    }
                    return [4, fetchApi(url, method, apiOptions.headers, apiOptions.body)];
                case 3:
                    response = _a.sent();
                    return [4, response.json()];
                case 4:
                    data = _a.sent();
                    if (response.ok) {
                        user_1 = User.createFromApiData(data);
                        setUser(user_1);
                        if (registrationStep === FormStepTypes.EmailPassword) {
                            enableAuthIcon(user_1);
                        }
                        if (registrationStep === FormStepTypes.NewsletterSurvey
                            && user_1.newsletter === false && user_1.survey === false) {
                            setErrors({ 'newsletter': 'auth.error.empty.newsletter' });
                        }
                        if (mode === FormBlockMode.Toggle
                            &&
                                (registrationStep === FormStepTypes.BirthdateGender
                                    && user_1.country !== null
                                    && user_1.jobTitle !== null)
                            ||
                                (registrationStep === FormStepTypes.JobIsFashionCountry
                                    && user_1.isEmailValidated === true
                                    && (user_1.registrationOrigin !== 'facebook' && user_1.registrationOrigin !== 'apple'))) {
                            setIsAuthModalOpen(false);
                            location.reload();
                            return [2];
                        }
                        setStatus(FormStatusTypes.Success);
                    }
                    else {
                        contentType = response.headers.get('content-type');
                        errorCode = response.status;
                        if (!contentType || contentType.indexOf('application/json') === -1 || errorCode !== 400) {
                            setErrors({ 'password': 'auth.error.default_register' });
                            setStatus(FormStatusTypes.Error);
                            return [2];
                        }
                        setErrors(data['errors']);
                        setStatus(FormStatusTypes.Error);
                    }
                    return [3, 6];
                case 5:
                    error_6 = _a.sent();
                    setStatus(FormStatusTypes.Error);
                    setErrors({ 'global': 'auth.error.default_register' });
                    return [3, 6];
                case 6: return [2];
            }
        });
    }); };
    useEffect(function () {
        setParams(function (params) { return (__assign(__assign({}, params), user)); });
    }, [user]);
    var enableAuthIcon = function (user) {
        var accountMenuModeLogged = document.querySelector('.account-menu--logged-in');
        var accountMenuModeConnect = document.querySelector('.account-menu--logged-out');
        if (accountMenuModeLogged) {
            accountMenuModeConnect.style.display = 'none';
            accountMenuModeLogged.style.display = 'block';
            var dropDown = accountMenuModeLogged.querySelector('.tds-dropdown');
            if (dropDown) {
                var accountIdentifierBlock = dropDown.querySelector('#identifierBlock');
                var accountIdentifier = document.createElement('p');
                accountIdentifier.innerText = translate('auth.status.logged.title') + user.slug;
                if (accountIdentifierBlock) {
                    accountIdentifierBlock.appendChild(accountIdentifier);
                }
            }
        }
    };
    var getCurrentYear = function () {
        return new Date().getFullYear().toString();
    };
    var NewsletterPdfExample = function () {
        var newsletterExampleUrl = generateDownloadLink('reports/TAGWALK-2023-in-numbers-6582fad5df987.pdf', {
            'utm_source': 'website',
            'utm_content': 'inscription_newsletter_download'
        });
        var translation = translate('auth.form.type.create_account.label.is_newsletter_example', {
            'NEWSLETTER_PDF_EXAMPLE': newsletterExampleUrl
        });
        return (React.createElement("p", { className: "is-survey-rewards", dangerouslySetInnerHTML: { __html: translation } }));
    };
    return (React.createElement("form", { onSubmit: isConnect ? handleConnection : handleInscription },
        errors['global'] &&
            React.createElement(ErrorHolder, null,
                React.createElement("p", null, translate(errors['global']))),
        registrationStep === FormStepTypes.EmailPassword && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "email form-group" },
                React.createElement("input", { id: 'email', type: 'email', className: 'form-control', disabled: status === FormStatusTypes.Loading, placeholder: translate('auth.form.type.create_account.placeholder.email'), value: params.email, onChange: function (event) {
                        var _a;
                        setParams(__assign(__assign({}, params), (_a = {}, _a['email'] = event.target.value.toLowerCase(), _a)));
                    }, autoComplete: "username" })),
            errors['email'] &&
                React.createElement(ErrorHolder, null,
                    React.createElement("p", null, translate(errors['email']))),
            React.createElement("div", { className: "form-group password" },
                React.createElement("input", { id: 'password', type: isPasswordVisible ? 'text' : 'password', className: 'form-control', disabled: status === FormStatusTypes.Loading, placeholder: translate('auth.form.type.create_account.placeholder.password'), value: params.password, onChange: function (event) {
                        var _a;
                        setParams(__assign(__assign({}, params), (_a = {}, _a['password'] = event.target.value, _a)));
                    }, autoComplete: isConnect ? "current-password" : "new-password" }),
                React.createElement(EyeIcon, { isPasswordVisible: isPasswordVisible, setIsPasswordVisible: setIsPasswordVisible })),
            isConnect && registrationStep === 1 && errors['password'] !== 'auth.error.bad_credentials' &&
                React.createElement(ForgotPassword, null),
            errors['password'] &&
                React.createElement(ErrorHolder, null,
                    React.createElement("p", null, translate(errors['password'])),
                    errors['password'] === 'auth.error.bad_credentials' &&
                        React.createElement(ForgotPassword, null)),
            React.createElement("div", { id: "hCaptcha" }),
            errors['captcha'] &&
                React.createElement(ErrorHolder, null,
                    React.createElement("p", null, translate(errors['captcha']))))),
        registrationStep === FormStepTypes.BirthdateGender && (React.createElement(React.Fragment, null,
            (user === null || user === void 0 ? void 0 : user.isAccountComplete) === false && (user === null || user === void 0 ? void 0 : user.timeDifferenceCreatedAtNow) > 24 ?
                React.createElement(React.Fragment, null,
                    React.createElement(Title, null, translate('auth.form.type.create_account.account_not_complete.title')),
                    React.createElement("p", null, translate('auth.form.type.create_account.account_not_complete.description')))
                :
                    React.createElement(Title, null, translate('auth.form.type.create_account.step.description.details')),
            React.createElement("div", { className: "form-group birthdate-container " + (focused || (focused === false && params.birthdate !== null) ? 'unset' : '') },
                React.createElement("input", { id: 'birthdate', type: 'date', className: 'form-control', disabled: status === FormStatusTypes.Loading, value: formatDateForInput(params.birthdate), onChange: function (event) {
                        var _a;
                        setParams(__assign(__assign({}, params), (_a = {}, _a['birthdate'] = event.target.value, _a)));
                    }, max: getCurrentYear() + "-01-01", onFocus: function () { return setFocused(true); }, onBlur: function () { return setFocused(false); } }),
                React.createElement("div", { className: "placeholder-text" }, translate('auth.form.type.create_account.placeholder.birthdate'))),
            errors['birthdate'] &&
                React.createElement(ErrorHolder, null,
                    React.createElement("p", null, translate(errors['birthdate']))),
            React.createElement(Select, { key: 'gender', id: 'gender', ref: genderFieldRef, placeholder: translate('auth.form.type.create_account.placeholder.gender'), options: genderOptions, value: genderOptions.find(function (option) { return option.value === params.gender; }), isDisabled: status === FormStatusTypes.Loading, onChange: function (option) {
                    var _a;
                    setParams(__assign(__assign({}, params), (_a = {}, _a['gender'] = option === null || option === void 0 ? void 0 : option.value, _a)));
                } }),
            errors['gender'] &&
                React.createElement(ErrorHolder, null,
                    React.createElement("p", null, translate(errors['gender']))))),
        registrationStep === FormStepTypes.JobIsFashionCountry && (React.createElement(React.Fragment, null,
            (user === null || user === void 0 ? void 0 : user.isAccountComplete) === false && (user === null || user === void 0 ? void 0 : user.timeDifferenceCreatedAtNow) > 24 ?
                React.createElement(React.Fragment, null,
                    React.createElement(Title, null, translate('auth.form.type.create_account.account_not_complete.title')),
                    React.createElement("p", { className: "text-center" }, translate('auth.form.type.create_account.account_not_complete.description')))
                :
                    (user === null || user === void 0 ? void 0 : user.jobNeedsUpdate) === true && (user === null || user === void 0 ? void 0 : user.timeDifferenceCreatedAtNow) > 24 ?
                        React.createElement("p", { className: "custom-title", dangerouslySetInnerHTML: { __html: translate('auth.form.type.create_account.job_needs_update.description') } })
                        :
                            React.createElement(Title, null, translate('auth.form.type.create_account.step.description.last_step')),
            React.createElement(Select, { key: 'jobTitle', id: 'jobTitle', ref: jobTitleFieldRef, isDisabled: status === FormStatusTypes.Loading, placeholder: translate('auth.form.type.create_account.placeholder.job_category'), value: jobOptions.find(function (job) { return job.value === params.jobTitle; }), options: jobOptions, onChange: function (option) {
                    var _a;
                    setParams(__assign(__assign({}, params), (_a = {}, _a['jobTitle'] = option === null || option === void 0 ? void 0 : option.value, _a)));
                } }),
            errors['jobTitle'] &&
                React.createElement(ErrorHolder, null,
                    React.createElement("p", null, translate(errors['jobTitle']))),
            ((user === null || user === void 0 ? void 0 : user.jobNeedsUpdate) === false || (user === null || user === void 0 ? void 0 : user.jobNeedsUpdate) === undefined) && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "fashion-industry form-group" },
                    React.createElement("input", { type: "checkbox", id: "fashionIndustry", className: 'form-control', disabled: status === FormStatusTypes.Loading, checked: params.fashionIndustry, onChange: function (event) {
                            var _a;
                            setParams(__assign(__assign({}, params), (_a = {}, _a['fashionIndustry'] = event.target.checked, _a)));
                        } }),
                    React.createElement("label", { htmlFor: "fashionIndustry" }, translate('auth.form.type.create_account.label.is_fashion'))),
                React.createElement(Select, { key: 'country', id: "country", ref: countryFieldRef, placeholder: translate('auth.form.type.create_account.placeholder.country'), options: countryOptions, value: countryOptions.find(function (country) { return country.value === params.country; }), isDisabled: status === FormStatusTypes.Loading, onChange: function (option) {
                        var _a;
                        setParams(__assign(__assign({}, params), (_a = {}, _a['country'] = option === null || option === void 0 ? void 0 : option.value, _a)));
                    } }),
                errors['country'] &&
                    React.createElement(ErrorHolder, null,
                        React.createElement("p", null, translate(errors['country']))))))),
        registrationStep === FormStepTypes.NewsletterSurvey &&
            React.createElement("div", { className: errors['newsletter'] ? 'toggle-error-mode' : '' },
                React.createElement(Title, null, translate('auth.form.type.create_account.step.description.subscriptions_configuration')),
                errors['newsletter'] &&
                    React.createElement(ErrorHolder, null,
                        React.createElement("p", null, translate(errors['newsletter']))),
                React.createElement("div", { className: "toggle-switch" },
                    React.createElement("div", { className: "form-check" },
                        React.createElement("input", { type: "checkbox", id: "newsletter", className: 'toggle-input d-none', disabled: status === FormStatusTypes.Loading, checked: params.newsletter, onChange: function (event) {
                                var _a;
                                setParams(__assign(__assign({}, params), (_a = {}, _a['newsletter'] = event.target.checked, _a)));
                            } }),
                        React.createElement("label", { htmlFor: "newsletter", className: 'form-check-label' }),
                        React.createElement("span", null,
                            React.createElement("p", { className: "toggle-label", dangerouslySetInnerHTML: { __html: translate('auth.form.type.create_account.label.is_newsletter') } }),
                            React.createElement(NewsletterPdfExample, null)))),
                React.createElement("div", { className: "toggle-switch" },
                    React.createElement("div", { className: "form-check" },
                        React.createElement("input", { type: "checkbox", id: "survey", className: 'toggle-input d-none', disabled: status === FormStatusTypes.Loading, checked: params.survey, onChange: function (event) {
                                var _a;
                                setParams(__assign(__assign({}, params), (_a = {}, _a['survey'] = event.target.checked, _a)));
                            } }),
                        React.createElement("label", { htmlFor: "survey", className: 'form-check-label' }),
                        React.createElement("span", null,
                            translate('auth.form.type.create_account.label.is_survey'),
                            React.createElement("p", { className: "is-survey-rewards" },
                                React.createElement("i", null, translate('auth.form.type.create_account.label.is_survey_rewards'))))))),
        React.createElement("div", { className: "" + (registrationStep === FormStepTypes.BirthdateGender ? 'flex-end' : 'flex-center') },
            React.createElement("button", { type: "submit", className: "btn", disabled: status === FormStatusTypes.Loading },
                " ",
                status === FormStatusTypes.Loading ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "spinner" }),
                    translate(buttonTextMapping[buttonTextKey]))) : (translate(buttonTextMapping[buttonTextKey])),
                registrationStep === FormStepTypes.BirthdateGender &&
                    React.createElement("i", { className: "fa-solid fa-angle-right" }))),
        registrationStep === FormStepTypes.NewsletterSurvey &&
            React.createElement("button", { type: "button", className: "unset-styles button-link", onClick: function () { return mode === FormBlockMode.Link
                    ? window.location.href = generateRoute('homepage')
                    : location.reload(); } }, translate('auth.form.type.create_account.action.leave_process'))));
};
export { Form };
