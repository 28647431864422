const filters = $('form.filters > .filter');

/**
 * Export current state of filters on webpage
 */
export function currentState() {
    let state = {};
    filters.each(function () {
        let field = $(this).find('input,select').first();
        let value = field.val();
        if ($.isArray(value)) {
            value = value.join(',');
        }
        if (value) {
            state[field.attr('id')] = value;
        }
    });

    return state;
}
