import * as React from 'react';
import { translate } from '../../helpers';
var IconListElement = function (_a) {
    var text = _a.text;
    return (React.createElement("li", { className: "icon-list flex-center" },
        React.createElement("span", null,
            React.createElement("i", { className: "fa-solid fa-check" })),
        React.createElement("p", null, translate(text))));
};
export { IconListElement };
