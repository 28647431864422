document.addEventListener('DOMContentLoaded', function () {
    initAccountDropdown();
    initDropdown();
    initMenuTrigger();
    initModal();
    initSlideshows();
});
var hideBackdrop = function () {
    var backdrop = document.querySelector('.backdrop');
    if (!backdrop) {
        return;
    }
    backdrop.remove();
    document.body.classList.remove('backdrop--visible');
};
var initAccountDropdown = function () {
    document.addEventListener('dropdown:open', function (e) {
        var event = e;
        if (!!event.detail.element.closest('.account-menu--logged-in')) {
            showBackdrop();
        }
    });
    document.addEventListener('dropdown:close', function (e) {
        var event = e;
        if (!!event.detail.element.closest('.account-menu--logged-in')) {
            hideBackdrop();
        }
    });
};
var initDropdown = function () {
    var toggles = document.querySelectorAll('.tds-dropdown-toggle');
    var toggleDropdown = function (e) {
        var _a;
        var dropdown = (_a = e.target.parentElement) === null || _a === void 0 ? void 0 : _a.querySelector('.tds-dropdown');
        if (!dropdown) {
            return;
        }
        dropdown.classList.toggle('tds-dropdown--open');
        var eventName = dropdown.classList.contains('tds-dropdown--open') ? 'dropdown:open' : 'dropdown:close';
        var event = new CustomEvent(eventName, {
            detail: {
                element: dropdown
            }
        });
        document.dispatchEvent(event);
    };
    Array.from(toggles).map(function (toggle) {
        toggle.addEventListener('click', toggleDropdown);
    });
    document.addEventListener('click', function (e) {
        var target = e.target;
        if (!!target.closest('.tds-dropdown-toggle')) {
            return;
        }
        var openDropdowns = document.querySelectorAll('.tds-dropdown--open');
        Array.from(openDropdowns).map(function (dropdown) {
            dropdown.classList.remove('tds-dropdown--open');
            var event = new CustomEvent('dropdown:close', {
                detail: {
                    element: dropdown
                }
            });
            document.dispatchEvent(event);
        });
    });
};
var initMenuTrigger = function () {
    var openIcon = document.querySelector('.open-menu');
    if (openIcon) {
        openIcon.addEventListener('click', function () {
            document.body.classList.add('menu--opened');
        });
    }
    var closeIcon = document.querySelector('.close-menu');
    if (closeIcon) {
        closeIcon.addEventListener('click', function () {
            document.body.classList.remove('menu--opened');
        });
    }
};
var closeOpenModals = function () {
    var openModals = document.querySelectorAll('.modal--open');
    Array.from(openModals).forEach(function (modal) {
        if (modal.getAttribute('id') === 'authModalContent' || modal.getAttribute('id') === 'paywallBlockModalContent') {
            return;
        }
        modal.classList.remove('modal--open');
    });
};
var initModal = function () {
    document.addEventListener('click', function (e) {
        if (!e.target) {
            return;
        }
        var target = e.target.getAttribute('data-modal-target');
        if (!target) {
            return;
        }
        openModal(target);
    });
    document.addEventListener('click', function (e) {
        if (!e.target) {
            return;
        }
        var target = e.target;
        if (!target.closest('.modal')
            && !target.hasAttribute('data-modal-target')
            && !!document.querySelector('.modal--open')
            || target.classList.contains('close-modal')
            || target.classList.contains('close')) {
            closeOpenModals();
            hideBackdrop();
        }
    });
    document.addEventListener('keydown', function (e) {
        if (e.key === 'Escape') {
            closeOpenModals();
            hideBackdrop();
        }
    });
};
var openModal = function (target) {
    var modal = document.querySelector(target);
    if (!modal) {
        return;
    }
    closeOpenModals();
    modal.classList.add('modal--open');
    showBackdrop();
};
var showBackdrop = function () {
    var backdrop = document.createElement('div');
    backdrop.classList.add('backdrop');
    var body = document.body;
    body.prepend(backdrop);
    body.classList.add('backdrop--visible');
};
var initSlideshows = function () {
    var slideshows = document.querySelectorAll('.slideshow');
    if (slideshows.length === 0) {
        return;
    }
    slideshows.forEach(function (slideshow) {
        var slides = slideshow.querySelectorAll('.slide');
        var slidesList = Array.from(slides);
        slidesList.forEach(function (slide, index) {
            var id = '' + (index + 1);
            slide.setAttribute('data-slideshow-id', id);
        });
        slideshow.addEventListener('mouseenter', function () {
            slideshow.classList.add('slideshow--disabled');
        });
        slideshow.addEventListener('mouseleave', function () {
            slideshow.classList.remove('slideshow--disabled');
        });
        showSlideshowNextSlide(slideshow);
        initSlideshowNavigation(slideshow, slidesList);
    });
};
var showSlideshowNextSlide = function (slideshow) {
    var _a, _b;
    var delay = parseInt((_a = slideshow.getAttribute('data-slideshow-delay')) !== null && _a !== void 0 ? _a : '2000');
    if (slideshow.classList.contains('slideshow--disabled') === true) {
        setTimeout(function () { return showSlideshowNextSlide(slideshow); }, delay);
        return;
    }
    var activeSlide = slideshow.querySelector('.slide--active');
    if (!activeSlide) {
        (_b = slideshow.querySelector('.slide:first-child')) === null || _b === void 0 ? void 0 : _b.classList.add('slide--active');
        setTimeout(function () { return showSlideshowNextSlide(slideshow); }, delay);
        return;
    }
    activeSlide.classList.remove('slide--active');
    var nextSlide = activeSlide.nextElementSibling || slideshow.querySelector('.slide:first-child');
    if (!nextSlide) {
        return;
    }
    var activeDot = slideshow.querySelector('.slideshow-dot--active');
    activeDot === null || activeDot === void 0 ? void 0 : activeDot.classList.remove('slideshow-dot--active');
    nextSlide.classList.add('slide--active');
    var slideId = nextSlide.getAttribute('data-slideshow-id');
    var nextDot = slideshow.querySelector(".slideshow-dot[data-slideshow-id=\"" + slideId + "\"]");
    nextDot === null || nextDot === void 0 ? void 0 : nextDot.classList.add('slideshow-dot--active');
    setTimeout(function () { return showSlideshowNextSlide(slideshow); }, delay);
};
var initSlideshowNavigation = function (slideshow, slides) {
    var navigation = document.createElement('div');
    navigation.classList.add('slideshow-dots');
    slides.forEach(function (slide, index) {
        var _a;
        var dot = document.createElement('span');
        dot.classList.add('slideshow-dot');
        if (slide.classList.contains('slide--active') === true) {
            dot.classList.add('slideshow-dot--active');
        }
        var slideId = (_a = slide.getAttribute('data-slideshow-id')) !== null && _a !== void 0 ? _a : '' + (index + 1);
        dot.setAttribute('data-slideshow-id', slideId);
        dot.addEventListener('click', function () {
            var _a, _b;
            (_a = document.querySelector('.slide--active')) === null || _a === void 0 ? void 0 : _a.classList.remove('slide--active');
            var nextSlide = slideshow.querySelector(".slide[data-slideshow-id=\"" + slideId + "\"]");
            nextSlide === null || nextSlide === void 0 ? void 0 : nextSlide.classList.add('slide--active');
            (_b = document.querySelector('.slideshow-dot--active')) === null || _b === void 0 ? void 0 : _b.classList.remove('slideshow-dot--active');
            dot.classList.add('slideshow-dot--active');
        });
        navigation.insertAdjacentElement('beforeend', dot);
    });
    slideshow.insertAdjacentElement('beforeend', navigation);
};
export { openModal, showBackdrop, hideBackdrop };
