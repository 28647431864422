const {fetchApi, showSuccess, showError} = require("../common/helpers");
const {HttpMethods} = require("tagwalk-api-client/assets/js/common/enums");
const {handleError} = require("tagwalk-api-client/assets/js/errorHandler");

document.addEventListener('DOMContentLoaded', () => {
    const forms = document.querySelectorAll('.newsletter-form');

    if (forms.length > 0) {
        forms.forEach(form => {
            form.addEventListener('submit', submitForm);
        });
    }
});

const submitForm = async (e) => {
    e.preventDefault();

    const input = e.target.querySelector('input[name=email]');
    const email = input.value;

    const inputNewsletter = e.target.querySelector('input[name=newsletter_source]');
    const newsletterSource = inputNewsletter.value;

    try {
        const response = await fetchApi('newsletter_subscribe_widget_global', HttpMethods.post, {email, newsletter_source: newsletterSource});
        await handleError(response);

        if (!response.ok) {
            showError('newsletter.subscribe.flash.error');

            return;
        }

        showSuccess('newsletter.subscribed', undefined, true);
        input.value = '';
    } catch (err) {
        showError('newsletter.subscribe.flash.error');
    }
}
