import * as React from "react";
import { List } from './list';
import { useContext } from 'react';
import { AuthContext } from '../contexts';
var FooterDots = function () {
    var _a = useContext(AuthContext), registrationStep = _a.registrationStep, maxStepCount = _a.maxStepCount;
    return (React.createElement(List, { className: 'dots flex-center' }, Array.from({ length: maxStepCount }).map(function (_, index) {
        var finalClass = "step-pagination " + ((index + 1) === registrationStep ? 'active' : '');
        return (React.createElement("li", { key: index },
            React.createElement("div", { className: finalClass })));
    })));
};
export { FooterDots };
