import {generateRoute} from '../../../common/helpers';

const initCollectionDesignerChoiceModal = (data) => {
    if (!data['designers'] || Object.keys(data['designers']).length === 0) {
        return;
    }

    if (Object.keys(data['designers']).length > 1) {
        const designerModalSelect = document.querySelector('#brandManagerDesigners');

        if (designerModalSelect) {
            addOptionsDesignerModal(designerModalSelect, data);
        }

        return;
    }

    const manageCollectionLinks = document.querySelectorAll('.manage-collections');

    manageCollectionLinks.forEach(link => {
        link.removeAttribute('data-modal-target');
        link.setAttribute(
            'href',
            generateRoute('user_collections_list_by_designer', {'slug': Object.keys(data['designers'])[0]})
        );
    })
}

const addOptionsDesignerModal = (designerModalSelect, data) => {
    const designerModalSlug = document.querySelector('#designerSelectModal').getAttribute('data-slug');

    for (const [slug, value] of Object.entries(data['designers'])) {
        const option = document.createElement('option');
        option.setAttribute('value', slug);
        option.textContent = value;

        if (designerModalSlug && designerModalSlug === slug) {
            option.setAttribute('selected', true);
        }

        designerModalSelect.append(option);
    }

    redirectToDesignerCollection(designerModalSelect);
}

const redirectToDesignerCollection = (input) => {
    const submitButton = document.querySelector('#confirmDesigners');
    const changeDesignerBtn = document.querySelector('#changeDesigner');

    changeDesignerBtn?.addEventListener('click', () => {
        submitButton.classList.remove('d-none');

        submitButton?.addEventListener('click', () => {
            const designer = input.value;
            window.location.href = generateRoute('user_collections_list_by_designer', {slug: designer});
        });
    });

    input.addEventListener('change', () => {
        if (submitButton?.classList.contains('d-none')) {
            const designer = input.value;
            window.location.href = generateRoute('user_collections_list_by_designer', {slug: designer});
        }
    });
}


export {initCollectionDesignerChoiceModal};
