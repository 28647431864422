import * as React from "react";
import translate from "../../../../../tagwalk-api-client/assets/js/translator";
import { useContext, useEffect } from "react";
import { AuthContext } from "../contexts";
var queryParams = new URLSearchParams(window.location.search);
var SignInCreateAccountToggle = function (_a) {
    var setErrors = _a.setErrors;
    var _b = useContext(AuthContext), isConnect = _b.isConnect, setIsConnect = _b.setIsConnect;
    useEffect(function () {
        var signIn = queryParams.get('sign_in');
        if (signIn === 'true') {
            setIsConnect(true);
        }
    }, []);
    var handleToggleChange = function () {
        setIsConnect(!isConnect);
        setErrors([]);
    };
    return (React.createElement("div", { className: "action-toggle" },
        React.createElement("input", { type: "checkbox", className: "action-toggle-checkbox", value: isConnect.toString(), onChange: handleToggleChange, checked: !isConnect }),
        React.createElement("div", { className: "toggle-page" },
            React.createElement("span", { className: "sign-in" }, translate('auth.form.type.connect.title')),
            React.createElement("span", { className: "create-account" }, translate('auth.form.type.create_account.title'))),
        React.createElement("div", { className: "layer" })));
};
export { SignInCreateAccountToggle };
