import '../../design/homepage/index.scss';

import * as $ from 'jquery';
import {fuzzySearch} from 'tagwalk-api-client/assets/js/common/fuzzySearch';
import {fetchApi, generateRoute, showError} from '../common/helpers';
import * as cookies from '../common/cookies';
import * as tts from './../common/tts';
import {HttpMethods} from "tagwalk-api-client/assets/js/common/enums";

document.addEventListener('DOMContentLoaded', async () => {
    await initRandomBanner();
    initSearchBar();
    initFilters();
    initPoll();
    fixSelect2DropdownsPosition();
    initAnalytics();
    collectionSlideOnArrowClick();
    initTextToScroll();
    adjustCarousel();
    window.addEventListener('resize', adjustCarousel);
});

let autoplayInterval;

const initRandomBanner = async () => {
    const bannerSection = document.querySelector('#search');
    const fallbackImageSrc = '/images/fallback_homepage_banner.png';

    try {
        const response = await fetchApi('api_homepage_banner_show_random', HttpMethods.get);

        if (!response.ok) {
            handleBannerError(bannerSection, fallbackImageSrc);

            return;
        }

        const banner = await response.json();

        initInsertBanner(banner, bannerSection);
    } catch (error) {
        handleBannerError(bannerSection, fallbackImageSrc);
    }
}

const handleBannerError = (bannerSection, fallbackImageSrc) => {
    bannerSection.style.backgroundImage = 'url(' + fallbackImageSrc + ')';
    bannerSection.style.backgroundSize = 'cover';
}

const initInsertBanner = (banner, bannerSection) => {
    const bannerFile = banner.video_link ? createVideoElement(banner) : createImgElement(banner);
    const bannerLink = banner.link ? createLinkElement(banner) : null;

    (bannerLink || bannerSection).appendChild(bannerFile);

    if (bannerLink && banner.link) {
        bannerSection.appendChild(bannerLink);
    }

    const metadata = bannerSection.querySelector('.banner-metadata');

    if (!metadata) {
        return;
    }

    const metadataLinks = metadata.querySelectorAll('a');

    if (banner.link) {
        metadataLinks.forEach((link) => link.setAttribute('href', banner.link))
    }

    updateMetadata(metadata, banner);
}

const createImgElement = (banner) => {
    const bannerImg = document.createElement('img');
    bannerImg.setAttribute('src', banner.image);
    bannerImg.setAttribute('alt', banner.title);

    return bannerImg;
}

const createVideoElement = (banner) => {
    const bannerVideo = document.createElement('video');
    bannerVideo.autoplay = true;
    bannerVideo.disablePictureInPicture = true;
    bannerVideo.loop = true;
    bannerVideo.muted = true;
    bannerVideo.playsInline = true;

    const videoSource = document.createElement('source');
    videoSource.setAttribute('src', banner['video_link']);
    videoSource.setAttribute('type', 'video/mp4');

    bannerVideo.appendChild(videoSource);

    return bannerVideo;
}

const createLinkElement = (banner) => {
    const bannerLink = document.createElement('a');
    bannerLink.setAttribute('href', banner.link);
    bannerLink.classList.add('banner-link');

    if (banner.designer_slug) {
        bannerLink.classList.add('tts-client');
        bannerLink.setAttribute('data-slug', banner.designer_slug);
    }

    return bannerLink;
}

const updateMetadata = (metadata, banner) => {
    const title = metadata.querySelector('.title');

    if (title && banner.title) {
        title.innerText = banner.title;
    }

    const subtitle = metadata.querySelector('.subtitle');

    if (subtitle && banner.subtitle) {
        subtitle.innerText = banner.subtitle;
    }
}

const initTextToScroll = () => {
    const marquees = document.querySelectorAll('.ribbon-black .marquee');

    marquees.forEach((marquee) => {
        const textElement = marquee.querySelector('p');
        const initialText = textElement.innerText;
        const textFull = textElement?.getAttribute('data-content');
        const text = textElement?.innerText;
        const sliceLength = text?.length;

        const isMobile = window.matchMedia("(max-width: 768px)").matches;

        if (textFull && textFull.length > sliceLength) {
            if (isMobile) {
                initLoop(marquee, textElement, textFull);

                return;
            }

            const cardElement = marquee.closest('.contains-text-slider');

            if (!cardElement) {
                return;
            }

            cardElement.addEventListener('mouseover', () => {
                initLoop(marquee, textElement, textFull);
            })

            cardElement.addEventListener('mouseout', () => {
                marquee.style.animation = `paused`;
                marquee.innerText = initialText;
            })
        }
    })
}

const initLoop = (marquee, textElement, textFull) => {
    textElement.style.opacity = '0';

    const spaceBefore = document.createTextNode('\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0');
    const spaceAfter = document.createTextNode('\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0');
    const content = document.createTextNode(textFull);

    marquee.innerText = textFull;
    marquee.insertBefore(spaceBefore, marquee.firstChild);
    marquee.insertBefore(content, marquee.firstChild);
    marquee.insertBefore(spaceAfter, marquee.firstChild);

    marquee.style.animation = `marquee 6s infinite linear`;
}

const collectionSlideOnArrowClick = () => {
    const collectionBlock = document.querySelector('.collections');
    const arrowLeft = collectionBlock?.querySelector('.iconoir-nav-arrow-left');
    const arrowRight = collectionBlock?.querySelector('.iconoir-nav-arrow-right');

    if (!arrowLeft || !arrowRight) {
        return;
    }

    const collectionItemsBlock = collectionBlock.querySelector('.collections-items');
    displayProperArrowsOnScroll(collectionBlock, collectionItemsBlock, arrowLeft, arrowRight);

    const scrollSize = 800;

    arrowLeft.addEventListener('click', () => {
        const newScrollLeft = Math.max(collectionItemsBlock.scrollLeft - scrollSize, 0);

        collectionItemsBlock.scrollTo({
            left: newScrollLeft,
            behavior: "smooth"
        });
    })

    arrowRight.addEventListener('click', () => {
        const maxScrollLeft = collectionItemsBlock.scrollWidth - collectionItemsBlock.clientWidth;
        const newScrollLeft = Math.min(collectionItemsBlock.scrollLeft + scrollSize, maxScrollLeft);

        collectionItemsBlock.scrollTo({
            left: newScrollLeft,
            behavior: "smooth"
        });
    })
}

const displayProperArrowsOnScroll = (collectionBlock, collectionItemsBlock, arrowLeft, arrowRight) => {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;

    if (isMobile) {
        return;
    }

    const rightScrollingEdge = collectionItemsBlock.scrollWidth - collectionItemsBlock.clientWidth;
    const scrollThreshold = 20;

    const updateArrowsVisibility = () => {
        arrowLeft.style.display = collectionItemsBlock.scrollLeft > scrollThreshold ? 'block' : 'none';
        arrowRight.style.display = collectionItemsBlock.scrollLeft < rightScrollingEdge - scrollThreshold ? 'block' : 'none';
    };

    collectionItemsBlock.addEventListener('scroll', updateArrowsVisibility);
    collectionItemsBlock.addEventListener('mouseenter', updateArrowsVisibility);

    collectionBlock.addEventListener('mouseleave', () => {
        arrowLeft.style.display = 'none';
        arrowRight.style.display = 'none';
    });
}

const initSearchBar = () => {
    const searchBar = $('#mixedSearch');
    searchBar.select2({
        placeholder: searchBar.attr('data-placeholder'),
        matcher: fuzzySearch,
        dropdownParent: $('.keywords-filter')
    });

    searchBar.on('change.select2', () => {
        searchBar.prop('disabled', true);

        const data = searchBar.select2('data');

        if (data.length === 0) {
            return;
        }

        const option = data[0].element;
        const type = option?.getAttribute('data-type');

        if (!option || !type) {
            return;
        }

        location.href = type === 'designer'
            ? generateRoute('collection_search', {designer: option.value})
            : generateRoute('look_search', {tags: [option.value]});
    });

    document.addEventListener('keyup', (event) => {
        const target = event.target;

        if (target.getAttribute('aria-controls') !== 'select2-mixedSearch-results') {
            return;
        }

        const placeholder = document.querySelector('.keywords-filter .select2-selection__placeholder');
        placeholder.style.opacity = !!target.value ? 0 : 1;
    }, true);
}

const initPoll = () => {
    const poll = document.querySelector('.poll');

    if (!poll) {
        return;
    }

    const choices = Array.from(poll.querySelectorAll('.poll-choice'));

    if (hasAnsweredPoll(poll) === true) {
        showPollResults(poll, choices);

        return;
    }

    const isMultiple = !!poll.getAttribute('data-multiple');

    if (isMultiple) {
        initMultipleChoicesPoll(poll, choices);

        return;
    }

    initSingleChoicePoll(poll, choices);
}

const hasAnsweredPoll = (poll) => {
    const slug = poll.getAttribute('data-slug');

    return document.cookie.split(';').some(
        (cookie) => cookie.includes('poll_answered=' + slug)
    );
}

const initSingleChoicePoll = (poll, choices) => {
    const pollSlug = poll.getAttribute('data-slug');

    choices.forEach(choice => {
        choice.addEventListener('click', async () => {

            if (hasAnsweredPoll(poll) === true) {
                return;
            }

            const id = choice.getAttribute('data-id');
            const data = await sendPollVotes(pollSlug, [id]);

            updatePollData(poll, choices, data);
            poll.classList.add('poll--answered');
        });
    });
}

const initMultipleChoicesPoll = (poll, choices) => {
    const pollSlug = poll.getAttribute('data-slug');

    choices.forEach(choice => {
        choice.addEventListener('click', () => {
            if (hasAnsweredPoll(poll) === true) {
                return;
            }

            choice.classList.toggle('poll-choice--selected');
        });
    });

    poll.querySelector('button').addEventListener('click', async () => {
        const selectedChoices = Array.from(document.querySelectorAll('.poll-choice--selected'));
        const choicesIds = selectedChoices.map(choice => choice.getAttribute('data-id'));

        const data = await sendPollVotes(pollSlug, choicesIds);
        updatePollData(poll, choices, data);

        selectedChoices.forEach(choice => choice.classList.remove('poll-choice--selected'));
        poll.classList.add('poll--answered');
    });
}

const sendPollVotes = async (slug, answers) => {
    const url = generateRoute('poll_vote', {
        'poll_slug': slug,
        'choices_id': answers
    }, false);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {'X-Requested-With': 'XMLHttpRequest'}
        });

        if (!response.ok) {
            showError('poll.vote.error');
        }

        let cookieExpirationDate = new Date();
        cookieExpirationDate.setTime(cookieExpirationDate.getTime() + (14 * 24 * 60 * 60 * 1000)); // 14 days from now
        cookies.create('poll_answered', slug, cookieExpirationDate, '/');

        return await response.json();
    } catch {
        showError('poll.vote.error');
    }
}

const updatePollData = (poll, choices, data) => {
    poll.setAttribute('data-votes-total-count', data['votes_total_count']);
    choices.forEach(choice => {
        const choiceId = choice.getAttribute('data-id');
        const choiceData = data.choices.find(item => parseInt(choiceId) === item.id);
        choice.setAttribute('data-votes-count', choiceData['votes_count']);
    });

    showPollResults(poll, choices);
}

const showPollResults = (poll, choices) => {
    let maxVotesCount = 0;

    choices.forEach(choice => {
        const choiceVotesCount = choice.getAttribute('data-votes-count');
        maxVotesCount = choiceVotesCount > maxVotesCount ? choiceVotesCount : maxVotesCount;
    });

    const votesTotalCount = poll.getAttribute('data-votes-total-count');
    const backgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--gray-2');

    choices.forEach(choice => {
        const choiceVotesCount = choice.getAttribute('data-votes-count');
        const result = Math.round(choiceVotesCount * 100 / votesTotalCount);
        const width = choiceVotesCount === maxVotesCount ? 100 : Math.round(choiceVotesCount * 100 / maxVotesCount);

        choice.style.background = `linear-gradient(90deg, ${backgroundColor} ${width}%, white 0%)`;
        choice.style.justifyContent = 'space-between';
        choice.style.textAlign = 'left';

        choice.querySelector('.poll-choice-result').innerText = `${result}%`;
    });
}

/**
 * When opened, filters take more space than when they're closed. Because of that, select2 don't know how to position
 * the dropdown at the right place when opening a filter without closing one placed before.
 * This hack is here to fix this weird issue.
 */
const fixSelect2DropdownsPosition = () => {
    $('.secondary-filters select').each((index, select) => {
        $(select).on('select2:open', (e) => {
            const target = e.target.nextElementSibling;

            setTimeout(() => {
                const position = target.getBoundingClientRect();
                const dropdown = $('body > .select2-container--open');

                dropdown.css('top', position.top + target.offsetHeight);
                dropdown.css('left', position.left);
            }, 10);
        });
    });
}

const initAnalytics = () => {
    initAnalyticsView();
    initAnalyticsClick();
}

const initIsPartlyInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const windowWidth = window.innerWidth || document.documentElement.clientWidth;

    const blockHeight = rect.bottom - rect.top;
    const blockWidth = rect.right - rect.left;

    const minHeightVisible = windowHeight * 0.1;
    const minWidthVisible = windowWidth * 0.1;

    return (
        rect.top + blockHeight >= 0 &&
        rect.left + blockWidth >= 0 &&
        rect.bottom - blockHeight <= windowHeight &&
        rect.right - blockWidth <= windowWidth &&
        blockHeight >= minHeightVisible &&
        blockWidth >= minWidthVisible
    );
}

const initAnalyticsView = () => {
    const processedElements = new Set();
    const elements = document.querySelectorAll('[data-block-type]');
    const homepageMainBanner = document.querySelector('[data-block-type="homepage-banner-main"]');
    const event = 'client_view';

    handleAnalyticsView(homepageMainBanner, processedElements, event);

    document.addEventListener('scroll', () => {
        elements.forEach((element) => {
            handleAnalyticsView(element, processedElements, event);
        })
    })
}

const handleAnalyticsView = (element, processedElements, event) => {
    const blockIsInViewport = initIsPartlyInViewport(element);

    if (blockIsInViewport === true && processedElements.has(element) === false) {
        const blockType = element.getAttribute('data-block-type');
        const clientLinks = element.querySelectorAll('.tts-client');
        const clientSlugs = Array.from(clientLinks).map(item => item.getAttribute('data-slug')).filter(slug => slug);

        tts.custom({
            event: event,
            uri: document.location,
            utm_content: blockType,
            clients: clientSlugs
        }, 'homepage');

        processedElements.add(element);
    }
}

const initAnalyticsClick = () => {
    const clientLinks = document.querySelectorAll('.tts-client');

    clientLinks.forEach(clientLink => {
        clientLink.addEventListener('click', (e) => {
            e.preventDefault();
            const clientBlockType = clientLink.closest('[data-block-type]').getAttribute('data-block-type');
            const slug = clientLink.getAttribute('data-slug');

            tts.click(clientLink.href, {clients: slug || null, utm_content: clientBlockType})
                .then(() => {
                    window.location.href = clientLink.href;
                });
        });
    });
}

const initFilters = () => {
    const filterType = $('#type');
    filterType.select2({
        allowClear: true,
        placeholder: filterType.attr('data-placeholder'),
        dropdownParent: $('#typeFilterContainer')
    });

    filterType.on('change.select2', filterChange);

    const filterCity = $('#city');
    filterCity.select2({
        allowClear: true,
        placeholder: filterCity.attr('data-placeholder'),
        dropdownParent: $('#cityFilterContainer')
    });

    filterCity.on('change.select2', filterChange);

    const filterSeason = $('#season');
    filterSeason.select2({
        allowClear: true,
        placeholder: filterSeason.attr('data-placeholder'),
        dropdownParent: $('#seasonFilterContainer')
    });

    filterSeason.on('change.select2', filterChange);

    const filterDesigner = $('#designer');
    filterDesigner.select2({
        allowClear: true,
        placeholder: filterDesigner.attr('data-placeholder'),
        matcher: fuzzySearch,
        dropdownParent: $('#brandFilterContainer')
    });

    filterDesigner.on('change.select2', filterChange);
}

const filterChange = () => {
    $('form.filters').trigger('submit');
    $('.filter').find('select, input').prop('disabled', true);
}

const adjustCarousel = () => {
    const isMobile = window.innerWidth < 768;
    let reviews = document.querySelectorAll('.review-card');

    if (reviews.length === 0) {
        return;
    }

    if (isMobile) {
        createMobileSlides(reviews);
    } else {
        restoreDesktopSlides(reviews);
    }

    document.querySelector('.inner-carousel').style.transform = 'translateX(0px)';
    const dotsContainer = document.querySelector('.map .dots');

    if (dotsContainer) {
        dotsContainer.innerHTML = '';
    }


    const numberOfSlides = document.querySelectorAll('.slide').length;
    const maxDots = numberOfSlides > 4 ? 4 : numberOfSlides;

    for (let i = 0; i < maxDots; i++) {
        const dot = document.createElement('span');
        dot.className = i === 0 ? 'dot active' : 'dot';
        dot.setAttribute('data-slide', i);
        dot.addEventListener('click', initClickEventOnDots);
        dotsContainer.appendChild(dot);
    }

    startAutoplay();
}

const initClickEventOnDots = (event) => {
    const dot = event.target;
    const slideIndex = parseInt(dot.getAttribute('data-slide'));
    const slides = document.querySelectorAll('.slide');
    const slideWidth = document.querySelector('.slide').offsetWidth;
    const totalWidth = slideWidth * slideIndex;

    slides.forEach(slide => {
        slide.classList.remove('active');
    });

    if (slides[slideIndex]) {
        slides[slideIndex].classList.add('active');
    }

    document.querySelectorAll('.dot').forEach(d => d.classList.remove('active'));
    dot.classList.add('active');

    document.querySelector('.inner-carousel').style.transform = `translateX(-${totalWidth}px)`
    clearInterval(autoplayInterval);
    setTimeout(startAutoplay, 5000);
}

const createMobileSlides = (reviews) => {
    const carouselInner = document.querySelector('.inner-carousel');
    carouselInner.innerHTML = '';
    reviews.forEach((review, index) => {
        const newSlide = document.createElement('div');
        newSlide.className = 'slide';
        if (index === 0) {
            newSlide.classList.add('active');
        }
        newSlide.appendChild(review.cloneNode(true));
        carouselInner.appendChild(newSlide);
    });
}

const restoreDesktopSlides = (reviews) => {
    const carouselInner = document.querySelector('.inner-carousel');

    if (!carouselInner) {
        return;
    }

    carouselInner.innerHTML = '';

    for (let i = 0; i < reviews.length; i += 3) {
        if (i + 2 < reviews.length) {
            const slide = document.createElement('div');
            slide.className = 'slide';
            if (i === 0) {
                slide.classList.add('active');
            }
            for (let j = i; j < i + 3; j++) {
                slide.appendChild(reviews[j].cloneNode(true));
            }
            carouselInner.appendChild(slide);
        }
    }
}

const autoplayCarousel = () => {
    const dots = document.querySelectorAll('.dot');
    let activeIndex = 0;

    dots.forEach((dot, index) => {
        if (dot.classList.contains('active')) {
            activeIndex = index;
        }
    });

    let nextIndex = (activeIndex + 1) % dots.length;
    dots[nextIndex].click();
}

const startAutoplay = () => {
    if (autoplayInterval) clearInterval(autoplayInterval);
    autoplayInterval = setInterval(autoplayCarousel, 5000);
}
