import { useEffect, useState } from 'react';
import { User } from '../types';
import { generateRoute } from '../../helpers';
var useAuth = function (registrationSource) {
    var _a = useState(), user = _a[0], setUser = _a[1];
    var _b = useState(1), registrationStep = _b[0], setRegistrationStep = _b[1];
    var _c = useState(false), isConnect = _c[0], setIsConnect = _c[1];
    var _d = useState(true), isAuthModalOpen = _d[0], setIsAuthModalOpen = _d[1];
    useEffect(function () {
        var route = generateRoute('login_status');
        fetch(route).then(function (response) {
            response.json().then(function (data) {
                if (data['logged'] === true) {
                    var user_1 = User.createFromApiData(data);
                    setUser(user_1);
                }
            });
        });
    }, []);
    var getRegistrationStep = function () {
        if (!user) {
            return 1;
        }
        if ((!user.birthdate || !user.gender) && (user.email || user.registrationOrigin === 'facebook')) {
            return 2;
        }
        if (((!user.jobTitle || !user.country) && (user.birthdate && user.gender)) || user.jobNeedsUpdate === true) {
            return 3;
        }
        if ((!user.newsletter && !user.survey) && user.email && user.birthdate && user.gender && user.jobTitle && user.country) {
            return 4;
        }
        if ((user.newsletter || user.survey) || (user.registrationOrigin === 'facebook' && !user.email)) {
            return 5;
        }
        return 1;
    };
    useEffect(function () {
        setRegistrationStep(getRegistrationStep());
        if (user !== undefined) {
            setIsConnect(false);
        }
    }, [user]);
    return {
        user: user,
        setUser: setUser,
        registrationStep: registrationStep,
        setRegistrationStep: setRegistrationStep,
        maxStepCount: 5,
        isConnect: isConnect,
        setIsConnect: setIsConnect,
        isAuthModalOpen: isAuthModalOpen,
        setIsAuthModalOpen: setIsAuthModalOpen,
        registrationSource: registrationSource
    };
};
export { useAuth };
