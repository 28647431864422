var FormBlockMode;
(function (FormBlockMode) {
    FormBlockMode[FormBlockMode["Link"] = 0] = "Link";
    FormBlockMode[FormBlockMode["Toggle"] = 1] = "Toggle";
})(FormBlockMode || (FormBlockMode = {}));
var FormStatusTypes;
(function (FormStatusTypes) {
    FormStatusTypes[FormStatusTypes["Idle"] = 0] = "Idle";
    FormStatusTypes[FormStatusTypes["Loading"] = 1] = "Loading";
    FormStatusTypes[FormStatusTypes["Success"] = 2] = "Success";
    FormStatusTypes[FormStatusTypes["Error"] = 3] = "Error";
})(FormStatusTypes || (FormStatusTypes = {}));
var FormStepTypes;
(function (FormStepTypes) {
    FormStepTypes[FormStepTypes["EmailPassword"] = 1] = "EmailPassword";
    FormStepTypes[FormStepTypes["BirthdateGender"] = 2] = "BirthdateGender";
    FormStepTypes[FormStepTypes["JobIsFashionCountry"] = 3] = "JobIsFashionCountry";
    FormStepTypes[FormStepTypes["NewsletterSurvey"] = 4] = "NewsletterSurvey";
})(FormStepTypes || (FormStepTypes = {}));
var ActionTypes;
(function (ActionTypes) {
    ActionTypes["Register"] = "api_register_index";
    ActionTypes["Patch"] = "api_user_patch";
})(ActionTypes || (ActionTypes = {}));
var RegistrationSources;
(function (RegistrationSources) {
    RegistrationSources["sharedMoodboard"] = "shared_moodboard";
})(RegistrationSources || (RegistrationSources = {}));
export { FormBlockMode, FormStatusTypes, FormStepTypes, RegistrationSources, ActionTypes };
