export default function () {
    const emailLinks = document.querySelectorAll('[data-mail]');

    Array.from(emailLinks).map(link => {
        link.addEventListener('click', () => {
            let href = `mailto:${link.dataset.mail.split('').reverse().join('')}`;

            if (link.hasAttribute('data-subject') === true) {
                href += `?subject=${link.getAttribute('data-subject')}`
            }

            link.href = href;
        });
    });
}
