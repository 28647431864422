/**
 * Filters the select choices based on values typed into the choice input
 *
 * @param {object} input
 * @param {object} selectOption
 *
 * @returns {object|null}
 */
const fuzzySearch = (input, selectOption) => {
    let inputText = input.term;

    if(inputText) {
        let words = inputText.split(' ');
        for (let i = 0; i < words.length; i++){
            let wordLength = words[i].length;
            if(wordLength <= 1 && words.length <= 2) {
                inputText = words.join('');
            }
        }
    }

    if (!inputText) {
        return selectOption;
    }

    const inputArray = tokenize(inputText);
    const selectOptionText = selectOption.text;
    const tokenizedOption = tokenize(selectOptionText);

    let counter = 0;

    for (let element of inputArray) {
        for (let word of tokenizedOption) {
            if (matches(element, word)) {
                counter++;
            }
        };
    };

    if (inputArray.length === counter) {
        return selectOption;
    }
    return null;
}

const levenshteinDistance = (s, t) => {
    if (!s.length) return t.length;
    if (!t.length) return s.length;
    const arr = [];
    for (let i = 0; i <= t.length; i++) {
        arr[i] = [i];
        for (let j = 1; j <= s.length; j++) {
            arr[i][j] = i === 0 ? j : Math.min(arr[i - 1][j] + 1, arr[i][j - 1] + 1, arr[i - 1][j - 1] + (s[j - 1] === t[i - 1] ? 0 : 1));
        }
    }
    return arr[t.length][s.length];
};

const tokenize = (value) => {
    return value.normalize('NFD')
        .replace(/[’'-/]/g, "")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-zA-Z 0-9]+/g, " ")
        .split(' ')
        .map((value) => value.toLowerCase())
        .filter(function (value, index, array) {
            return value !== '' && array.indexOf(value) === index;
        });

}

const matches = (element, word) => {
    const fuzzy = levenshteinDistance(element, word);
    const pattern = new RegExp('\\b' + element, 'i');
    if ((element.length <= 5 && fuzzy <= 1) || (element.length > 5 && fuzzy <= 2) || pattern.test(word)) {
        return true;
    }
};

export {fuzzySearch};
