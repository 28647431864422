export default function multipleTagsClear() {
    const svg = `
        <svg class="clear-cross-icon" xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16" enable-background="new 0 0 16 16" xml:space="preserve">
            <g>
            <line fill="none" stroke="#9EA0A3" stroke-width="1" x1="0" y1="0" x2="16" y2="16" className="svg-element"></line>
            <line fill="none" stroke="#9EA0A3" stroke-width="1" x1="0" y1="16" x2="16" y2="0" className="svg-element"></line>
            </g>
        </svg>`

    const filterTags = document.querySelector('.filter-tags select');

    if (filterTags && filterTags.children.length >= 1) {
        let clearFieldButton = document.createElement('button');
        clearFieldButton.classList.add('custom-clear');
        clearFieldButton.insertAdjacentHTML('afterbegin', svg);

        let field = document.getElementsByClassName('filter-tags')[0];
        if (field) {
            let zoomIcon = field.getElementsByClassName('fa-search')[0];
            if (document.getElementsByClassName('select2-selection__choice').length > 0) {
                zoomIcon.style.display = 'none';
                field.appendChild(clearFieldButton);
                $(clearFieldButton).on('click', function () {
                    $("#tags").empty();
                });
            }
        }
    }
}
