import * as React from 'react';
import { List } from './list';
import { IconListElement } from "./iconListElement";
import { generateRoute, translate } from "../../helpers";
import { useContext, useMemo } from "react";
import { AuthContext } from "../contexts";
import { FormStepTypes, RegistrationSources } from "../enums";
var DescriptionBlock = function () {
    var _a = useContext(AuthContext), isConnect = _a.isConnect, registrationStep = _a.registrationStep, registrationSource = _a.registrationSource;
    var initBgClass = useMemo(function () {
        if (registrationStep === FormStepTypes.NewsletterSurvey) {
            return 'bg-image-newsletter-survey';
        }
        if (registrationSource === RegistrationSources.sharedMoodboard) {
            return 'bg-image-moodboard';
        }
        if (isConnect) {
            return 'bg-image-connection';
        }
        return 'bg-image-inscription';
    }, [registrationSource, isConnect, registrationStep]);
    var initContext = useMemo(function () {
        if (window.innerWidth < 991
            && registrationStep < FormStepTypes.NewsletterSurvey
            && registrationSource !== RegistrationSources.sharedMoodboard) {
            return (React.createElement(React.Fragment, null,
                React.createElement("a", { href: generateRoute('homepage') },
                    React.createElement("i", { className: "fa-solid fa-arrow-left fa-xl" })),
                React.createElement("h2", { className: "mobile-description" }, translate('auth.description.unlock_engine_power'))));
        }
        if (registrationStep < FormStepTypes.NewsletterSurvey
            && registrationSource === RegistrationSources.sharedMoodboard) {
            return (React.createElement("h2", { className: "inscription-mode-moodboard-title" }, translate('auth.description.shared_moodboard')));
        }
        if (registrationStep < FormStepTypes.NewsletterSurvey
            && registrationSource !== RegistrationSources.sharedMoodboard) {
            return (React.createElement(React.Fragment, null,
                React.createElement("a", { href: generateRoute('homepage') },
                    React.createElement("div", { className: "logo flex-center" },
                        React.createElement("img", { src: "/images/tagwalk-logo-white.svg", alt: translate('auth.alt.logo') }))),
                React.createElement(List, null,
                    React.createElement(IconListElement, { text: translate('auth.description.search_keywords') }),
                    React.createElement(IconListElement, { text: translate('auth.description.access_trends') }),
                    React.createElement(IconListElement, { text: translate('auth.description.receive_data') }),
                    React.createElement(IconListElement, { text: translate('auth.description.create_share_moodboard') }))));
        }
        return;
    }, [registrationSource, registrationStep]);
    var finalBlockClass = "left-block flex-center " + initBgClass;
    return (React.createElement("div", { className: finalBlockClass },
        React.createElement("div", { className: "content-block" }, initContext)));
};
export { DescriptionBlock };
