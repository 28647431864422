import * as React from "react";
var EyeIcon = function (_a) {
    var isPasswordVisible = _a.isPasswordVisible, setIsPasswordVisible = _a.setIsPasswordVisible;
    var toggleEyeIcon = function () {
        setIsPasswordVisible(!isPasswordVisible);
    };
    return (React.createElement("button", { type: "button", onClick: toggleEyeIcon, className: "display-password unset-styles" }, isPasswordVisible ?
        React.createElement("i", { className: "fa-light fa-eye-slash" }) :
        React.createElement("i", { className: "fa-light fa-eye" })));
};
export { EyeIcon };
