var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { fetchApi, translate } from "./helpers";
import * as tts from './tts';
import { HttpMethods } from "tagwalk-api-client/assets/js/common/enums";
var initFooterBanner = function () { return __awaiter(void 0, void 0, void 0, function () {
    var footer, footerBannerSection, footerImg, footerTitle, footerLink, response, data, error_1;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                footer = document.querySelector('.footer');
                footerBannerSection = footer === null || footer === void 0 ? void 0 : footer.querySelector('.ad');
                if (!footerBannerSection) {
                    return [2];
                }
                footerImg = footerBannerSection.querySelector('img');
                footerTitle = footerBannerSection.querySelector('h4');
                footerLink = footerBannerSection.querySelector('a');
                if (!footerImg || !footerTitle || !footerLink) {
                    initDefaultBanner(footerImg, footerTitle, footerLink);
                    return [2];
                }
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 5]);
                return [4, fetchApi('api_footer_banner_list', HttpMethods.get)];
            case 2:
                response = _b.sent();
                if (!response.ok) {
                    initDefaultBanner(footerImg, footerTitle, footerLink);
                    return [2];
                }
                return [4, response.json()];
            case 3:
                data = _b.sent();
                footerImg.setAttribute('src', data[0].image);
                footerTitle.innerText = (_a = data[0]) === null || _a === void 0 ? void 0 : _a.title;
                footerLink.setAttribute('href', data[0].link);
                initAnalytics(footerBannerSection, footerLink);
                return [3, 5];
            case 4:
                error_1 = _b.sent();
                initDefaultBanner(footerImg, footerTitle, footerLink);
                return [3, 5];
            case 5: return [2];
        }
    });
}); };
var initDefaultBanner = function (footerImg, footerTitle, footerLink) {
    footerImg.setAttribute('src', 'https://cdn.tag-walk.com/custom,fit_cover,width_1152,height_702/capturedecran20240304a1451271-dedadb4d.png');
    footerTitle.innerText = translate('footer.report.title');
    footerLink.setAttribute('href', 'https://www.tag-walk.com/en/fall-winter-2024-catwalk-report');
};
var initAnalytics = function (footerBannerSection, footerLink) {
    initAnalyticsView(footerBannerSection, footerLink);
    initAnalyticsClick(footerLink);
};
var initAnalyticsClick = function (footerLink) {
    footerLink.addEventListener('click', function (e) {
        e.preventDefault();
        tts.click(footerLink.getAttribute('href'), { utm_content: 'footer_banner' })
            .then(function () {
            window.location.href = footerLink.href;
        });
    });
};
var initAnalyticsView = function (footerBannerSection, footerLink) {
    var processedElements = new Set();
    var main = document.querySelector('main');
    var route = main === null || main === void 0 ? void 0 : main.getAttribute('data-route');
    var observer = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
            if (processedElements.has(entry) === false && entry.isIntersecting) {
                tts.custom({
                    event: 'footer_banner_view',
                    uri: document.location,
                    utm_content: 'footer_banner',
                    utm_source: footerLink.getAttribute('href')
                }, route);
                processedElements.add(footerBannerSection);
            }
        });
    });
    observer.observe(footerBannerSection);
};
export { initFooterBanner };
