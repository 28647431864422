var HtmlEditorToolbarItemsEnum;
(function (HtmlEditorToolbarItemsEnum) {
    HtmlEditorToolbarItemsEnum["Alignment"] = "alignment";
    HtmlEditorToolbarItemsEnum["Bold"] = "bold";
    HtmlEditorToolbarItemsEnum["Heading"] = "heading";
    HtmlEditorToolbarItemsEnum["HtmlEmbed"] = "htmlEmbed";
    HtmlEditorToolbarItemsEnum["Italic"] = "italic";
    HtmlEditorToolbarItemsEnum["Link"] = "link";
})(HtmlEditorToolbarItemsEnum || (HtmlEditorToolbarItemsEnum = {}));
var HttpMethods;
(function (HttpMethods) {
    HttpMethods["get"] = "GET";
    HttpMethods["post"] = "POST";
    HttpMethods["patch"] = "PATCH";
    HttpMethods["put"] = "PUT";
    HttpMethods["delete"] = "DELETE";
    HttpMethods["head"] = "HEAD";
})(HttpMethods || (HttpMethods = {}));
var MediaUploaderModeEnum;
(function (MediaUploaderModeEnum) {
    MediaUploaderModeEnum[MediaUploaderModeEnum["Replace"] = 0] = "Replace";
    MediaUploaderModeEnum[MediaUploaderModeEnum["Add"] = 1] = "Add";
})(MediaUploaderModeEnum || (MediaUploaderModeEnum = {}));
export { HtmlEditorToolbarItemsEnum, HttpMethods, MediaUploaderModeEnum };
