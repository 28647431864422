import {getFingerprints} from './fingerprint';
import {Router} from './router';
import * as filters from './filters';

const cookies = require('./cookies');

// the element to look for get page data-attributes
const selector = $('main');

/**
 * Send photo view action tts event
 *
 * @param {string} photos
 * @access public
 */
export async function page(photos = undefined) {

    if (!isEnabled()) {
        return;
    }

    const query = Object.assign(
        {'uri': window.location.href},
        photos !== undefined ? {'photos': photos} : getPhotos(),
        await buildQuery(),
    );

    navigator.sendBeacon(Router.generate(
        'tts_page',
        {'route': selector.attr('data-route')}
    ), objectToFormData(query));
}

const buildQuery = async () => {
    return Object.assign(
        getCurrentPage(),
        getRouteParams(),
        getQuery(),
        getTotalCount(),
        await getUserIdentifyingData(),
        getUtm(),
        getSource(),
        filters.currentState(),
    )
}

function objectToFormData(object) {
    let formData = new FormData();
    const properties = Object.keys(object);
    for (const property of properties) {
        formData.append(property, object[property]);
    }

    return formData;
}

/**
 * Get current page query
 *
 * @access private
 */
function getQuery() {
    let dataQuery = {};
    if (selector.attr('data-query')) {
        try {
            dataQuery = selector.attr('data-query') ? JSON.parse(selector.attr('data-query')) : {};
        } catch (e) {
            console.error('parsing error: ', e);
        }
    }
    return dataQuery;
}

/**
 * Get current page photos
 *
 * @access private
 */
function getPhotos() {
    return selector.attr('data-photos') ? {'photos': selector.attr('data-photos')} : {};
}

/**
 * Get query total count
 *
 * @access private
 */
function getTotalCount() {
    const count = $('#count-results').attr('data-total-count');

    return count ? {'count': count} : {};
}

/**
 * Get query current page
 *
 * @access private
 */
function getCurrentPage() {
    return {'page': selector.attr('data-current-page')};
}

const getUserIdentifyingData = async () => {
    return {
        'sessid': selector.attr('data-sessid'),
        ...await getFingerprints()
    };
}

/**
 * Get UTM source & content
 *
 * @access private
 */
function getUtm() {
    let utm = {},
        source = selector.attr('data-utm-source'),
        content = selector.attr('data-utm-content');

    if (source) {
        utm['utm_source'] = source;
    }

    if (content) {
        utm['utm_content'] = content;
    }

    return utm;
}

/**
 * Get source parameter
 *
 * @access private
 */
function getSource() {
    let params = {},
        source = selector.attr('data-source');

    if (source) {
        params['source'] = source;
    } else if (document.referrer.length > 0) {
        let referrer = new URL(document.referrer);
        let current  = new URL(document.URL);

        if (referrer.hostname == current.hostname) {
            params['source'] = document.referrer;
        }
    }

    return params;
}

/**
 * Get route params
 *
 * @access private
 */
function getRouteParams() {
    return JSON.parse(selector.attr('data-route-params'));
}

/**
 * Send media tts event
 * @access public
 * @param {string} slug
 * @param {string} event "photo_zoom", "photo_view", "photo_list", "moodboard_add"
 */
export async function media(slug, event) {

    if (!isEnabled()) {
        return;
    }

    const query = Object.assign(
        {
            'uri': window.location.href,
            'event': event
        },
        await buildQuery()
    );

    navigator.sendBeacon(Router.generate(
        'tts_media',
        {'slug': slug}
    ), objectToFormData(query));
}

export async function click(uri, params) {
    if (!isEnabled()) {
        return;
    }

    const query = Object.assign(
        {
            'uri': uri,
            'event': 'click',
            ...params
        },
        await buildQuery()
    );

    delete query['count'];
    query['source'] = window.location.href;

    navigator.sendBeacon(Router.generate(
        'tts_click',
    ), objectToFormData(query));
}

/**
 * Send streetstyle tts event
 * @access public
 * @param {string} slug
 * @param {string} event "photo_zoom", "photo_view", "photo_list", "moodboard_add"
 */
export async function streetstyle(slug, event) {

    if (!isEnabled()) {
        return;
    }

    const query = Object.assign(
        {
            'uri': window.location.href,
            'event': event
        },
        await buildQuery()
    );
    navigator.sendBeacon(Router.generate(
        'tts_streetstyle',
        {'slug': slug}
    ), objectToFormData(query));
}

/**
 * checks if notts query param is not set
 * @returns {boolean}
 */
function isEnabled() {
    let urlParams = new URLSearchParams(location.search);
    return !urlParams.has('notts');
}

/**
 * Send tts event (beacon) on aff. link click
 */
export async function  purchase(slug, uri) {

    if (!isEnabled()) {
        return;
    }

    const query = Object.assign(
        {
            'event': 'purchase',
            'uri': uri,
            'photos': slug,
        },
        await buildQuery()
    );
    navigator.sendBeacon(Router.generate(
        'tts_page',
        {'route': 'affiliation_purchase'}
    ), objectToFormData(query));
}

/**
 * Send tts event (beacon) on document download
 */
export async function download(identifier, uri) {

    if (!isEnabled()) {
        return;
    }

    const query = Object.assign(
        {
            'event': 'download',
            'uri': uri,
        },
        await buildQuery()
    );
    navigator.sendBeacon(Router.generate(
        'tts_page',
        {'route': identifier}
    ), objectToFormData(query));
}

/**
 * Send custom tts event use params
 */
export async function custom(params, route) {

    if (!isEnabled()) {
        return;
    }

    const query = Object.assign(
        params,
        await buildQuery()
    );
    navigator.sendBeacon(Router.generate(
        'tts_page',
        {'route': route}
    ), objectToFormData(query));
}

const generateFingerprint = () => {
    const random = Math.random().toString(36).substr(2);
    const fingerprint = [Date.now(), random].join('-');

    localStorage.setItem('fingerprint', fingerprint);

    return fingerprint;
}

export const handleUtmParametersWithCookies = () => {
    const date = new Date();
    date.setTime(date.getTime() + (10 * 60 * 1000)); // 10 minutes

    const utmParameters = ['utm_source', 'utm_campaign', 'utm_content'];
    const urlParams = new URLSearchParams(window.location.search);

    utmParameters.forEach(param => {
        const value = urlParams.get(param);

        if (value) {
            cookies.create(param, value, date, '/')
        }
    });
};
