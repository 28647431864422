const cookies = require('./cookies');
const ua = navigator.userAgent;
const cookiesName = 'os-device';
const osDeviceBanner = document.querySelector('.os-device-banner');
const supportedLanguages = ['en', 'es', 'fr', 'it', 'zh'];
const lang = document.documentElement.lang;

const init = () => {
    if (cookies.get(cookiesName)) {
        return;
    }

    const closeTriggers = document.querySelectorAll('.os-device-close, .os-device-close #badge');
    Array.from(closeTriggers).forEach(trigger => {
        trigger.addEventListener('click', () => {
            osDeviceBanner.remove();
            cookies.create(
                cookiesName,
                'true',
                new Date(new Date().getTime() + 30 * 1000 * 60 * 60 * 24),
                '/',
                document.domain
            );
        });
    });

    if (!isMobile()) {
        return;
    }

    fillHtml(getOsDetails());
    setTimeout(() => {
        osDeviceBanner.classList.remove('d-none');
    }, 1000);
}

const isMobile = () => {
    return !(null === ua.match(/Android/i) && null === ua.match(/iPhone|iPad|iPod/i));
}

const getOsDetails = () => {
    let i18n = 'en';

    for (let i = 0; i < supportedLanguages.length; i++) {
        if (supportedLanguages.indexOf(lang) !== -1) {
            i18n = lang;
            break;
        }
    }

    return {
        'os': ua.match(/Android/i) !== null ? 'Android' : 'iOs',
        'img': ua.match(/Android/i) !== null ? '/images/google-play-badge-' + i18n + '.png' : '/images/app-store-badge-' + i18n + '.svg',
        'link': ua.match(/Android/i) !== null ? 'market://details?id=com.tagwalk.tagwalk' : 'itms-apps://itunes.apple.com/app/tagwalk/id1435948980',
    }
}

const fillHtml = (params) => {
    osDeviceBanner.querySelector('.os-device-badge a').setAttribute('href', params['link']);
    osDeviceBanner.querySelector('#badge').setAttribute('src', params['img']);
    osDeviceBanner.querySelector('#badge').classList.add('badge-mobile-' + params['os'].toLowerCase());
}

module.exports = {init};
