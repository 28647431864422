/**
 * if response is not 2** throws
 * new error for catch to handle
 *
 * @param {Response} response
 * @returns {*}
 */
exports.handleError = async (response) => {
    if (response.ok) {
        const contentType = response.headers.get('content-type');

        if (contentType && contentType.indexOf('application/json') !== -1) {
            return response.json();
        }

        return response.text();
    }

    let jsonResponse = await response.json();

    if (undefined !== jsonResponse.message) {
        throw new Error(jsonResponse.message);
    }

    throw new Error(response.status.toString())
}

/**
 *
 * @param {ErrorEvent} error
 * @param {string} messageId
 */
exports.showThrownError = (error, messageId = 'error') => {
    const flash = document.querySelector('.flash-js');

    flash.innerHTML = `<div class="alert alert-error">${translateError(error, messageId)}</div>`;
    flash.scrollIntoView({behavior: 'smooth', block: 'center'});
}

/**
 * @param {ErrorEvent} error
 * @param {string} messageId
 */
exports.getThrownError = (error, messageId = 'error') => {
    return translateError(error, messageId);
}

const translateError = (error, messageId = 'error') => {
    if (isNaN(error.message)) {
        return error;
    }

    switch (error.message) {
        case '409':
            return Translator.trans(`${messageId}.conflict`)
        case '404':
            return Translator.trans(`${messageId}.not_found`);
        case '403':
            return Translator.trans(`${messageId}.access_denied`);
        case '400':
            return Translator.trans(`${messageId}.bad_request`);
        default:
            return Translator.trans(`${messageId}.error`);
    }
}
