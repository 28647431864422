import '../../scss/global.scss';

import {initFooterBanner} from "./footer";

/** Vendors */
const $ = require('jquery');
require('popper.js');
require('bootstrap');
require('select2/dist/js/select2');
require('./polyfill');
$.fn.select2.defaults.set("theme", "bootstrap");
require('navigator.sendbeacon');
import imgLazyload from './imgLazyload';
import {insightsCountNotifierAdd} from "./insightsCountNotifierAdd";
import * as tts from './tts';
import * as user from '../user/auth';
import decodeMailtos from './mailtoDecoder';
import osDetection from './osDevice';
import multipleTagsClear from './multipleTagsClear';
import {toast, Flip} from "react-toastify";
import './design';
import '../newsletter/form';
import {initPromoBand} from "./initPromoBand";
import {initSubmenuBackground} from "./submenuBackground";

/** Business logic */
require('./scrollTop');
const clearIcon = $('.filters-block').attr('data-clear-icon');

document.addEventListener('DOMContentLoaded', () => {
    toast.configure({
        autoClose: 5000,
        transition: Flip,
        theme: 'light'
    });
});

/** Init */
$(document).ready(async function () {
    initSubmenuBackground();
    await user.checkLogin();
    await insightsCountNotifierAdd();
    await initFooterBanner();
    await initPromoBand();
    imgLazyload();
    osDetection.init();
    decodeMailtos();
    multipleTagsClear();
    $('.select2-selection__clear, .select2-selection__choice__remove').empty().append(clearIcon).show();
    tts.page();
    tts.handleUtmParametersWithCookies();
});
