var initSubmenuBackground = function () {
    var menuTriggers = document.querySelectorAll('.sub-menu-trigger');
    var backgroundElement = document.querySelector('.sub-menu-background');
    if (menuTriggers.length === 0 || !backgroundElement) {
        return;
    }
    var trigger;
    var showBackground = function (e) {
        backgroundElement.style.height = '200px';
        backgroundElement.style.animation = 'slideIn 0.4s ease forwards';
        var currentTrigger = e.currentTarget;
        if (currentTrigger.classList.contains('sub-menu-trigger')) {
            trigger = currentTrigger;
        }
        var subMenu = trigger.querySelector('.sub-menu');
        var iconUp = trigger.querySelector('.iconoir-nav-arrow-up');
        if (iconUp) {
            iconUp.classList.add('iconoir-nav-arrow-down');
            iconUp.classList.remove('iconoir-nav-arrow-up');
        }
        subMenu.style.display = 'block';
        var listItems = subMenu.querySelectorAll('li');
        listItems.forEach(function (li, index) {
            li.style.opacity = '0';
            li.style.transform = 'translateY(-20px)';
            li.style.animation = "slideIn 0.3s ease forwards";
            li.style.animationDelay = (index + 1) * 0.1 + "s";
        });
    };
    var hideBackground = function () {
        backgroundElement.style.height = '0';
        backgroundElement.style.animation = 'slideOut 0.4s ease forwards';
        var subMenu = trigger.querySelector('.sub-menu');
        var iconDown = trigger.querySelector('.iconoir-nav-arrow-down');
        if (iconDown) {
            iconDown.classList.remove('iconoir-nav-arrow-down');
            iconDown.classList.add('iconoir-nav-arrow-up');
        }
        subMenu.style.display = 'none';
        var listItems = subMenu.querySelectorAll('li');
        listItems.forEach(function (li) {
            li.style.opacity = '0';
            li.style.transform = 'translateY(-20px)';
            li.style.animation = '';
            li.style.animationDelay = '';
        });
    };
    menuTriggers.forEach(function (trigger) {
        trigger.addEventListener('mouseover', showBackground);
        trigger.addEventListener('mouseout', hideBackground);
    });
    backgroundElement.addEventListener('mouseover', showBackground);
    backgroundElement.addEventListener('mouseout', hideBackground);
};
export { initSubmenuBackground };
