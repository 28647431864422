import * as React from 'react';
import { useContext, useState } from 'react';
import { SignInCreateAccountToggle } from './signInCreateAccountToggle';
import { Form } from './form';
import { SocialAuth } from './socialAuth';
import { TermsConditions } from './termsConditions';
import { FooterDots } from './footerDots';
import { translate } from "../../helpers";
import { GoBack } from "./goBack";
import { FormStepTypes } from "../enums";
import { AuthContext } from '../contexts';
var FormBlock = function (_a) {
    var mode = _a.mode;
    var _b = useContext(AuthContext), registrationStep = _b.registrationStep, user = _b.user;
    var _c = useState([]), errors = _c[0], setErrors = _c[1];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "form-section" },
            window.innerWidth > 991 &&
                React.createElement("div", { className: "logo flex-center" },
                    React.createElement("img", { src: "/images/watermark-black.png", alt: translate('auth.alt.logo') })),
            registrationStep === FormStepTypes.EmailPassword && React.createElement(SignInCreateAccountToggle, { setErrors: setErrors }),
            React.createElement(Form, { errors: errors, setErrors: setErrors, mode: mode })),
        registrationStep === FormStepTypes.EmailPassword ? (React.createElement("div", { className: "third-party-connection-container" },
            React.createElement(SocialAuth, null),
            React.createElement(TermsConditions, null))) : (React.createElement("div", { className: "go-back-block" },
            registrationStep === FormStepTypes.JobIsFashionCountry
                && (user === null || user === void 0 ? void 0 : user.jobNeedsUpdate) === false &&
                React.createElement(GoBack, null, translate('auth.form.type.create_account.action.go_back')),
            (user === null || user === void 0 ? void 0 : user.jobNeedsUpdate) === false &&
                React.createElement(FooterDots, null)))));
};
export { FormBlock };
