import * as React from "react";
import { generateRoute, translate } from '../../helpers';
var TermsConditions = function () {
    var termsUrl = generateRoute('dynamic_page_show', { 'path': 'terms-and-conditions' });
    var privacyUrl = generateRoute('dynamic_page_show', { 'path': 'data-privacy-policy' });
    var translation = translate('auth.footer.terms_conditions_privacy_policy', {
        'TERMS_URL': termsUrl,
        'PRIVACY_URL': privacyUrl
    });
    return (React.createElement("div", { className: "terms-conditions", dangerouslySetInnerHTML: { __html: translation } }));
};
export { TermsConditions };
