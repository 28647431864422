function get(name)
{
    name += "=";
    let decodedCookie = decodeURIComponent(document.cookie),
        cookie = decodedCookie.split(';');

    for (let i = 0; i < cookie.length; i++) {
        if (cookie[i].trim().indexOf(name) === 0) {
            return true;
        }
    }
    return false;
}

function create(name, value, expires, path, domain)
{
    let cookie = name + "=" + value + ";";

    if (expires) {
        cookie += "expires=" + expires.toGMTString() + ";";
    } else {
        expires = new Date(new Date().getTime() + parseInt(expires) * 3600 * 24 * 365 * 1000);
        cookie += "expires=" + expires.toGMTString() + ";";
    }

    if (path)
        cookie += "path=" + path + ";";
    if (domain)
        cookie += "domain=" + domain + ";";

    document.cookie = cookie;
}

module.exports = {
    get: get,
    create: create
};
