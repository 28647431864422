var User = (function () {
    function User(email) {
        this.slug = '';
        this.email = '';
        this.password = '';
        this.birthdate = '';
        this.gender = '';
        this.jobTitle = '';
        this.fashionIndustry = false;
        this.country = '';
        this.survey = false;
        this.newsletter = false;
        this.newsletterSource = '';
        this.registrationOrigin = '';
        this.isEmailValidated = false;
        this.isAccountComplete = false;
        this.jobNeedsUpdate = false;
        this.timeDifferenceCreatedAtNow = 0;
        this.email = email;
    }
    User.createFromApiData = function (data) {
        var user = new User(data === null || data === void 0 ? void 0 : data.email);
        user.slug = data === null || data === void 0 ? void 0 : data.slug;
        user.birthdate = data === null || data === void 0 ? void 0 : data.birthdate;
        user.gender = data === null || data === void 0 ? void 0 : data.gender;
        user.jobTitle = data === null || data === void 0 ? void 0 : data.job_title;
        user.fashionIndustry = data === null || data === void 0 ? void 0 : data.fashion_industry;
        user.country = data === null || data === void 0 ? void 0 : data.country;
        user.survey = data === null || data === void 0 ? void 0 : data.survey;
        user.newsletter = data === null || data === void 0 ? void 0 : data.newsletter;
        user.registrationOrigin = data === null || data === void 0 ? void 0 : data.registration_origin;
        user.isEmailValidated = data === null || data === void 0 ? void 0 : data.email_validated;
        user.isAccountComplete = data === null || data === void 0 ? void 0 : data.account_complete;
        user.jobNeedsUpdate = data === null || data === void 0 ? void 0 : data.job_needs_update;
        user.timeDifferenceCreatedAtNow = data === null || data === void 0 ? void 0 : data.time_difference_created_at_now;
        return user;
    };
    return User;
}());
export { User };
