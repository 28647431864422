import * as React from "react";
import { generateRoute, translate } from '../../helpers';
var ForgotPassword = function () {
    var resetUrl = generateRoute('forgot_password');
    var translation = translate('auth.form.type.connect.password_forgot', {
        'PASSWORD_FORGOT_URL': resetUrl
    });
    return (React.createElement("div", { className: "forgot-password", dangerouslySetInnerHTML: { __html: translation } }));
};
export { ForgotPassword };
