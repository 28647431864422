import { createContext } from 'react';
var DEFAULT_AUTH_CONTEXT = {
    user: undefined,
    setUser: function () { },
    registrationStep: 1,
    setRegistrationStep: function () { },
    maxStepCount: 5,
    isConnect: true,
    setIsConnect: function () { },
    isAuthModalOpen: true,
    setIsAuthModalOpen: function () { },
    registrationSource: undefined
};
var AuthContext = createContext(DEFAULT_AUTH_CONTEXT);
export { AuthContext };
