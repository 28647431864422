import * as React from "react";
import { generateRoute, translate } from "../../helpers";
var SocialAuth = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "third-party-connection flex-center" },
            React.createElement("hr", null),
            React.createElement("p", null, translate('auth.third_party_auth.title')),
            React.createElement("hr", null)),
        React.createElement("div", null,
            React.createElement("a", { href: generateRoute('redirect_facebook_auth', {}, false), className: "wrapper" },
                React.createElement("span", { className: "fa-stack" },
                    React.createElement("i", { className: "fa-brands fa-facebook" })),
                React.createElement("p", null, translate('auth.third_party_auth.facebook'))),
            React.createElement("a", { href: generateRoute('redirect_apple_auth', {}, false), className: "wrapper" },
                React.createElement("span", { className: "fa-stack" },
                    React.createElement("i", { className: "fal fa-brands fa-apple" })),
                React.createElement("p", null, translate('auth.third_party_auth.apple'))))));
};
export { SocialAuth };
